import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { useQuery, UseQueryResult } from "react-query";

import { DischargeRequest } from "../types";

interface DischargeStatus {
  has_pending_discharge_request: boolean;
  pending_discharge_requests: DischargeRequest[];
  needs_review: boolean;
}

export const getDischargeStatusQueryKey = (userId: number) => [
  "discharge-status",
  userId,
];

const useDischargeStatus = (
  userId: number
): UseQueryResult<DischargeStatus, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getDischargeStatusQueryKey(userId),
    () =>
      api
        .get<DischargeStatus>(`/v1/discharge_requests/member_status`, {
          params: { user_id: userId },
          headers: { Authorization: accessToken },
        })
        .then(({ data }) => data)
        .catch(transformAPIError),
    { enabled: !!accessToken && userId != null }
  );
};

export default useDischargeStatus;
