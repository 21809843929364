import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { useQuery, UseQueryOptions } from "react-query";

import { DischargeRequest } from "../types";

interface UseDischargeRequestsPayload {
  requestId?: number;
  userId?: number;
  status?: string;
}

export const getDischargeRequestsQueryKey = (
  payload?: UseDischargeRequestsPayload
) =>
  [
    "discharge-requests",
    payload?.status,
    payload?.requestId,
    payload?.userId,
  ].filter(Boolean);

export default function useDischargeRequests<T = DischargeRequest[]>(
  payload: UseDischargeRequestsPayload = {},
  options: UseQueryOptions<DischargeRequest[], Error, T> = {}
) {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getDischargeRequestsQueryKey(payload),
    ({ signal }) =>
      api
        .get<DischargeRequest[]>("/v1/discharge_requests", {
          signal,
          headers: {
            Authorization: accessToken,
          },
          params: {
            status: payload?.status,
            user_id: payload?.userId,
            request_id: payload?.requestId,
          },
        })
        .then(({ data }) => data)
        .catch(transformAPIError),
    {
      enabled: !!accessToken,
      ...options,
    }
  );
}
