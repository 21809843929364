import {
  DISCHARGE_OPTIONS,
  ENTIRELY,
  MOSTLY,
  NA,
  NOT_AT_ALL,
  PARTIALLY,
} from "@core/constants";
import usePrompt from "@core/hooks/usePrompt";
import { useSession } from "@core/hooks/useSession";
import {
  addMemberDischargeNote,
  Member,
  MemberDischargeNote,
} from "@core/services/nocd-api";
import { Appointment } from "@core/services/nocd-api/appointments";
import { Select, TextArea, TextField } from "@core/ui";
import getMemberFullName from "@core/utils/formatMemberFullName";
import { getDischargeRequestsQueryKey } from "@features/discharge-requests/hooks/useDischargeRequests";
import useDischargeStatus, {
  getDischargeStatusQueryKey,
} from "@features/discharge-requests/hooks/useDischargeStatus";
import CreateTransferRequestDialog from "@features/transfers/components/CreateTransferRequestDialog";
import { checkDischargeActionNeeded } from "@features/transfers/hooks/useIsDischargeActionNeeded";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  StyledEngineProvider,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { format, isValid } from "date-fns";
import { format as formatTZ, utcToZonedTime } from "date-fns-tz";
import { isEmpty } from "lodash";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import * as Yup from "yup";

import DischargeEmailPreviewDialog from "./DischargeEmailPreviewDialog";

const validationSchema = Yup.object().shape({
  discharge_reason: Yup.string().required("Required").typeError("Required"),
  adhered_to_treatment_plan: Yup.string()
    .required("Required")
    .typeError("Required"),
  discharge_barriers_and_notes: Yup.string()
    .required("Required")
    .typeError("Required"),
  discharge_care_and_referrals: Yup.string()
    .required("Required")
    .typeError("Required"),
});

const formLabelsTheme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#db3131",
          "&$error": {
            color: "#db3131",
          },
        },
      },
    },
  },
});

interface DischargeNoteFormProps {
  userId: number;
  member: Member;
  onSuccess: (data: Member) => void;
  setIsDischargeNoteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isDischargeNoteDialogOpen: boolean;
  isUpdate?: boolean;
  existingDischargeNote?: MemberDischargeNote;
  scheduledAppointments?: Appointment[];
  currentClinician?: string;
}

const isAppointmentDateValid = (appt: Appointment) => {
  const value = appt.start_time_in_iso8601_utc;
  const valueAsDate = value ? new Date(value) : null;
  return isValid(valueAsDate) ? valueAsDate : undefined;
};
const formatAppointmentInClinicianTime = (
  apptDate: Date,
  clinicianTimezone: string | null
) => {
  const timeZone = clinicianTimezone ?? "America/Chicago";

  if (apptDate) {
    const appointmentInClinicianTime = utcToZonedTime(apptDate, timeZone);

    const date = formatTZ(
      appointmentInClinicianTime,
      "EEE, MMM d, yyy h:mm aaa zzz",
      {
        timeZone,
      }
    );
    return date;
  }
  return undefined;
};

const formatAppointmentListItem = (appt: Appointment) => {
  const apptDate = isAppointmentDateValid(appt);

  const appointmentInClinicianTime = formatAppointmentInClinicianTime(
    apptDate,
    appt?.clinician_timezone
  );

  return (
    <Box alignItems="center" py={1}>
      <Typography variant="subtitle2">
        Appointment ID: {appt.id}- {appt.session_type} -{" "}
        {appointmentInClinicianTime}
      </Typography>
    </Box>
  );
};

export default function DischargeNoteForm({
  userId,
  member,
  onSuccess,
  setIsDischargeNoteDialogOpen,
  isDischargeNoteDialogOpen,
  isUpdate,
  existingDischargeNote,
  scheduledAppointments,
  currentClinician,
}: DischargeNoteFormProps): JSX.Element {
  const qc = useQueryClient();

  const { data: dischargeStatus } = useDischargeStatus(userId);

  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const prompt = usePrompt<string>();
  const [isPreviewEmailOpen, setIsPreviewEmailOpen] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<Partial<MemberDischargeNote>>({
    defaultValues: {
      discharge_reason: existingDischargeNote?.discharge_reason || null,
      adhered_to_treatment_plan:
        existingDischargeNote?.adhered_to_treatment_plan || null,
      discharge_barriers_and_notes:
        existingDischargeNote?.discharge_barriers_and_notes || null,
      discharge_care_and_referrals:
        existingDischargeNote?.discharge_care_and_referrals || null,
      discharge_at:
        existingDischargeNote?.discharge_at || new Date().toISOString(),
      updated_by: session.user.email || "",
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (formValues: Partial<MemberDischargeNote>) => {
    if (!dischargeStatus?.needs_review) {
      const isDischargeActionNeeded = await checkDischargeActionNeeded(
        {
          userId,
          clinicianEmail: session.user.email,
          dischargeReason: formValues.discharge_reason,
        },
        accessToken
      );

      if (isDischargeActionNeeded) {
        try {
          const newTransferRequestResult = await prompt.prompt();

          if (!newTransferRequestResult) {
            toast.error(
              "Discharge note submission cancelled. Transfer discharge requires an active transfer request."
            );
            return undefined;
          }
        } catch (err) {
          if (err instanceof Error) {
            // eslint-disable-next-line no-console
            console.error("Failed to create transfer request", err.message);
          }
        }
      }
    }

    const promise = addMemberDischargeNote(
      userId,
      formValues,
      accessToken,
      isUpdate,
      dischargeStatus?.needs_review && !isUpdate,
      existingDischargeNote ? existingDischargeNote.id : null
    )
      .then((data) => onSuccess(data))
      .then(() => setIsDischargeNoteDialogOpen(false))
      .then(() => qc.invalidateQueries(getDischargeStatusQueryKey(userId)))
      .then(() => qc.invalidateQueries(getDischargeRequestsQueryKey()))
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error("Failed to save discharge note", error.message);
        throw error;
      });

    return toast.promise(promise, {
      loading: "Saving discharge note...",
      success: "Discharge note successfully saved",
      error: (error) => error.message,
    });
  };

  const dischargeReason = watch("discharge_reason");

  const memberAppointmnetsList = isEmpty(scheduledAppointments ?? [])
    ? null
    : scheduledAppointments.map((appt) => {
        return (
          <ListItem key={appt.id}>
            <ListItemText primary={formatAppointmentListItem(appt)} />
          </ListItem>
        );
      });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={formLabelsTheme}>
        <Dialog
          fullWidth
          maxWidth="md"
          open={isDischargeNoteDialogOpen}
          onClose={() => setIsDischargeNoteDialogOpen(false)}
        >
          <DialogTitle id="form-dialog-title">
            Create Discharge Note
          </DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <TextArea
                    label="Member Name"
                    value={`${getMemberFullName(
                      member.first_name,
                      member.last_name,
                      member.preferred_name
                    )}`}
                    rows={1}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    label="Discharge Date Time"
                    value={format(new Date(), "MMM d, yyy h:mm aaa")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>

                {isUpdate ? (
                  <Grid item xs={6}>
                    <TextArea
                      label="Updated at"
                      value={format(new Date(), "MMM d, yyy h:mm aaa")}
                      rows={1}
                      disabled
                    />
                  </Grid>
                ) : null}

                {memberAppointmnetsList ? (
                  <>
                    <Grid item xs={12}>
                      <Alert severity="warning">
                        <Typography>
                          Currently scheduled sessions with{" "}
                          {currentClinician ?? ""} listed below will be
                          cancelled after you submit the discharge note.{" "}
                        </Typography>
                      </Alert>
                    </Grid>
                    <Grid item xs={12}>
                      <List
                        sx={{
                          width: "100%",
                          maxHeight: 200,
                          minHeight: 200,
                          height: 200,
                          overflowY: "scroll",
                          border: "3px solid lightgray",
                          borderRadius: 2,
                        }}
                      >
                        {memberAppointmnetsList}
                      </List>
                    </Grid>
                  </>
                ) : null}

                <Grid item xs={12}>
                  <Controller
                    name="discharge_reason"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        value={field.value ?? []}
                        options={DISCHARGE_OPTIONS}
                        onChange={(_, nextValue) => field.onChange(nextValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Discharge Reason"
                            showRequiredIndicator
                            fullWidth
                            error={!!errors.discharge_reason}
                            helperText={errors?.discharge_reason?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="adhered_to_treatment_plan"
                    control={control}
                    render={({ field }) => (
                      <Select
                        value={field.value}
                        name={field.name}
                        showRequiredIndicator
                        label="Adhered to Treatment"
                        fullWidth
                        error={!!errors.adhered_to_treatment_plan}
                        onChange={field.onChange}
                        helperText={errors?.adhered_to_treatment_plan?.message}
                        options={[
                          { value: NOT_AT_ALL },
                          { value: PARTIALLY },
                          { value: MOSTLY },
                          { value: ENTIRELY },
                          { value: NA },
                        ]}
                      />
                    )}
                  />
                </Grid>
                {dischargeReason &&
                watch("discharge_reason") !==
                  "Discharge - Transfer to another NOCD therapist" ? (
                  <Grid item xs={12}>
                    <Alert severity="info">
                      <Typography>
                        Please note that a discharge email notification will be
                        sent to the Member upon submission of this discharge
                        note.{" "}
                        <Button onClick={() => setIsPreviewEmailOpen(true)}>
                          See preview
                        </Button>
                      </Typography>
                    </Alert>
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <Typography fontWeight="fontWeightMedium" fontSize="1rem">
                    Discharge Summary / Barriers or Challenges to Treatment
                    <span
                      style={{
                        marginLeft: "2px",
                        color: "red",
                        fontSize: "20px",
                      }}
                    >
                      *
                    </span>
                  </Typography>
                  <Typography variant="body2">
                    Provide a BRIEF description of services provided, progress
                    made, barriers to treatment and/or continuing treatment.
                  </Typography>
                  <Controller
                    name="discharge_barriers_and_notes"
                    control={control}
                    render={({ field }) => (
                      <TextArea
                        {...field}
                        label=""
                        error={!!errors.discharge_barriers_and_notes}
                        helperText={
                          errors?.discharge_barriers_and_notes?.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography fontWeight="fontWeightMedium" fontSize="1rem">
                    Anticipated Need for Continued Care and Resource Referrals
                    <span
                      style={{
                        marginLeft: "2px",
                        color: "red",
                        fontSize: "20px",
                      }}
                    >
                      *
                    </span>
                  </Typography>
                  <Typography variant="body2">
                    Document what is needed for the Member for continued care
                    AND all resource referrals that you have provided them
                    before discharge. Referrals are to be documented in this
                    area as well as any crisis resources that may have been
                    provided.
                  </Typography>
                  <Typography variant="body2" mt={2}>
                    ALL MEMBERS, EXCEPT INTERNAL TRANSFERS, ARE PROVIDED A
                    MINIMUM 3 REFERRALS AT DISCHARGE.
                    <br />
                    1 local community resources/referral (at a minimum)
                    <br />
                    1 crisis support services
                    <br />1 other referral- (Examples: iocdf.org, psychology
                    today, IOP, PHP, residential, psychiatrist, etc.)
                  </Typography>
                  <Controller
                    name="discharge_care_and_referrals"
                    control={control}
                    render={({ field }) => (
                      <TextArea
                        {...field}
                        label=""
                        minRows={8}
                        error={!!errors.discharge_care_and_referrals}
                        helperText={
                          errors.discharge_care_and_referrals?.message
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setIsDischargeNoteDialogOpen(false)}
                color="primary"
              >
                Cancel
              </Button>

              <Button color="primary" type="submit" disabled={isSubmitting}>
                {dischargeStatus?.needs_review && !isUpdate
                  ? "Request discharge"
                  : "Submit"}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </ThemeProvider>
      <CreateTransferRequestDialog
        isOpen={prompt.isOpen}
        onClose={() => prompt.onConfirm(null)}
        userId={userId}
        isForDischargeNote
        onSuccess={prompt.onConfirm}
      />
      {isPreviewEmailOpen ? (
        <DischargeEmailPreviewDialog
          isOpen={isPreviewEmailOpen}
          onClose={() => setIsPreviewEmailOpen(false)}
          dischargeReason={dischargeReason}
          memberName={member?.first_name ?? ""}
        />
      ) : null}
    </StyledEngineProvider>
  );
}
