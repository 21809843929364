import { api, transformAPIError } from "@core/services/nocd-api";

interface IsDischargeActionNeededPayload {
  userId?: number;
  clinicianEmail?: string;
  dischargeReason?: string;
}

export const checkDischargeActionNeeded = (
  payload: IsDischargeActionNeededPayload,
  accessToken: string
) => {
  return api
    .get<0 | 1>(`/v1/check_discharge_action_needed`, {
      params: {
        user_id: payload.userId,
        clinician_email: payload.clinicianEmail,
        discharge_reason: payload.dischargeReason,
      },
      headers: {
        Authorization: accessToken,
      },
    })
    .then(({ data }) => data === 1)
    .catch(transformAPIError);
};
